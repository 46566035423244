/*
 * compare attribute must be a compare function returning -1, 1 or 0
 */
import { stringToDate } from '@/filters/dateFilters';

export class SortOption {
  constructor(id, name, value, compare) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.compare = compare;
  }
}

export const dateCompare = (a, b) => {
  return stringToDate(a).getTime() - stringToDate(b).getTime();
};

export const numberCompare = (a, b) => {
  return a - b;
};
