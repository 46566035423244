var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "flex w-full p-2",
      class: [_vm.data.class, _vm.data.staticClass],
    },
    [
      _vm._t("wrapper", function () {
        return [
          _c(
            "div",
            { staticClass: "flex flex-grow flex-wrap w-56" },
            [_vm._t("aside"), _vm._t("section"), _vm._t("main")],
            2
          ),
          _c("div", { staticClass: "flex-no-grow" }, [_vm._t("actions")], 2),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }