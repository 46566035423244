var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("card-form", {
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _vm._t("title", function () {
                    return [_vm._v("Comments")]
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "form",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center content-center justify-end",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex justify-end w-full" },
                        [
                          _c(
                            "sun-form",
                            {
                              staticClass:
                                "flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative flex items-center content-center w-full m-2 xl:w-auto",
                                },
                                [
                                  _c("sun-input", {
                                    key: "search" + _vm.keyReset,
                                    staticClass: "w-full",
                                    attrs: {
                                      value: _vm.filters.author,
                                      placeholder: "User...",
                                    },
                                    on: { change: _vm.onUserChange },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center w-4 h-4 text-gray-600 search-svg-absolute",
                                    },
                                    [_c("search-svg")],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "w-full m-2 xl:w-56" },
                                [
                                  _c("sun-select", {
                                    attrs: {
                                      options: _vm.tags,
                                      "track-by": "value",
                                      label: "name",
                                      placeholder: "Select a tag",
                                    },
                                    model: {
                                      value: _vm.filters.tag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "tag", $$v)
                                      },
                                      expression: "filters.tag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("sun-date-picker", {
                                key: "date" + _vm.keyReset,
                                staticClass: "w-full m-2 xl:w-auto",
                                attrs: {
                                  value: _vm.filters.date,
                                  "single-date-picker": false,
                                  "show-dropdowns": false,
                                  direction: "left",
                                  "linked-calendars": "",
                                  separator: "-",
                                  "class-input":
                                    "w-full block shadow appearance-none w-full border focus:outline-none\n              focus:shadow-outline text-gray-700",
                                  name: "initialDate",
                                },
                                on: { change: _vm.onDatePickerChange },
                              }),
                              _c(
                                "div",
                                { staticClass: "flex justify-end w-full" },
                                [
                                  _c(
                                    "sun-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.filtersEmpty,
                                          expression: "!filtersEmpty",
                                        },
                                      ],
                                      staticClass: "mr-2 text-xs custom-p-1",
                                      attrs: { variant: "pill", color: "gray" },
                                      on: { click: _vm.clearFilters },
                                    },
                                    [_vm._v(" Clear ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex w-full items-center content-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap w-full" },
                        [
                          _c("asterix-dropdown", {
                            staticClass: "m-2",
                            attrs: { options: _vm.sortOptions },
                            on: { change: _vm.onSort },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (slotProps) {
                                  return [
                                    _vm._v(" Order by "),
                                    slotProps.selected
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "w-full ml-1 font-bold text-orange-600",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  slotProps.selected.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap w-full justify-end" },
                        [
                          _vm.canCreate
                            ? _c(
                                "sun-button",
                                {
                                  staticClass:
                                    "px-4 m-4 bg-gray-700 custom-p-1 hover:bg-gray-800",
                                  attrs: { variant: "pill", color: "gray" },
                                  on: { click: _vm.onNewComment },
                                },
                                [_vm._v(" + New comment ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  (_vm.filteredComments && _vm.filteredComments.length > 0) ||
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        _vm._l(_vm.filteredComments, function (comment) {
                          return _c("feed-comment", {
                            key: comment.id,
                            attrs: { comment: comment },
                            on: {
                              delete: function ($event) {
                                return _vm.onDeleteComment(comment)
                              },
                              edit: function ($event) {
                                return _vm.onEditComment(comment)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center h-full no-data-cnt",
                        },
                        [
                          _c("asterix-no-data", {
                            staticClass: "text-center",
                            attrs: {
                              title: _vm.noDataTitle,
                              subtitle: _vm.noDataSubtitle,
                            },
                          }),
                        ],
                        1
                      ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.showNewCommentModal
        ? _c("new-comment-modal", {
            attrs: { comment: _vm.commentToUpdate, tags: _vm.tags },
            on: {
              cancel: function ($event) {
                _vm.showNewCommentModal = false
              },
              submit: _vm.onSubmitComment,
            },
          })
        : _vm._e(),
      _c("delete-modal", {
        attrs: { open: _vm.showDeleteModal },
        on: {
          confirm: _vm.confirmDeleteModal,
          cancel: function ($event) {
            _vm.showDeleteModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [
                _vm._v(
                  " You are going to remove the comment. This action can not be undone."
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }