<template>
  <div>
    <card-form>
      <template #title><slot name="title">Comments</slot></template>
      <template #form>
        <div class="flex flex-wrap items-center content-center justify-end">
          <div class="flex justify-end w-full">
            <sun-form class="flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto">
              <div class="relative flex items-center content-center w-full m-2 xl:w-auto">
                <sun-input
                  :key="'search' + keyReset"
                  :value="filters.author"
                  class="w-full"
                  placeholder="User..."
                  @change="onUserChange"
                />
                <div class="flex items-center w-4 h-4 text-gray-600 search-svg-absolute">
                  <search-svg />
                </div>
              </div>

              <div class="w-full m-2 xl:w-56">
                <sun-select
                  v-model="filters.tag"
                  :options="tags"
                  track-by="value"
                  label="name"
                  placeholder="Select a tag"
                />
              </div>

              <sun-date-picker
                :key="'date' + keyReset"
                :value="filters.date"
                :single-date-picker="false"
                :show-dropdowns="false"
                :direction="'left'"
                linked-calendars
                separator="-"
                class="w-full m-2 xl:w-auto"
                class-input="w-full block shadow appearance-none w-full border focus:outline-none
                focus:shadow-outline text-gray-700"
                name="initialDate"
                @change="onDatePickerChange"
              />

              <div class="flex justify-end w-full">
                <sun-button
                  v-show="!filtersEmpty"
                  variant="pill"
                  color="gray"
                  class="mr-2 text-xs custom-p-1"
                  @click="clearFilters"
                >
                  Clear
                </sun-button>
              </div>
            </sun-form>
          </div>
        </div>
        <div class="flex w-full items-center content-center">
          <div class="flex flex-wrap w-full">
            <asterix-dropdown class="m-2" :options="sortOptions" @change="onSort">
              <template #default="slotProps">
                Order by
                <span v-if="slotProps.selected" class="w-full ml-1 font-bold text-orange-600">
                  {{ slotProps.selected.name }}
                </span>
              </template>
            </asterix-dropdown>
          </div>
          <div class="flex flex-wrap w-full justify-end">
            <sun-button
              v-if="canCreate"
              variant="pill"
              class="px-4 m-4 bg-gray-700 custom-p-1 hover:bg-gray-800"
              color="gray"
              @click="onNewComment"
            >
              + New comment
            </sun-button>
          </div>
        </div>

        <div v-if="(filteredComments && filteredComments.length > 0) || loading" class="mt-2">
          <feed-comment
            v-for="comment in filteredComments"
            :key="comment.id"
            :comment="comment"
            @delete="onDeleteComment(comment)"
            @edit="onEditComment(comment)"
          />
        </div>
        <div v-else class="flex items-center justify-center h-full no-data-cnt">
          <asterix-no-data :title="noDataTitle" :subtitle="noDataSubtitle" class="text-center" />
        </div>
      </template>
    </card-form>
    <new-comment-modal
      v-if="showNewCommentModal"
      :comment="commentToUpdate"
      :tags="tags"
      @cancel="showNewCommentModal = false"
      @submit="onSubmitComment"
    />

    <delete-modal :open="showDeleteModal" @confirm="confirmDeleteModal" @cancel="showDeleteModal = false">
      <template #description> You are going to remove the comment. This action can not be undone.</template>
    </delete-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PERMISSIONS } from '@/store/modules/auth/keys';
import { COMMENT_TAGS } from '@/model/shared/Tags';
import CardForm from '@/components/atoms/CardForm';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { SortOption } from '@/model/shared/SortOption';
import {
  filterDateAscCompare,
  filterDateDescCompare,
  filterTagCompare,
  filterAuthorCompare,
} from '@/utils/compareUtils';
import FeedComment from '@/components/organisms/shared/asterixComments/FeedComment';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'AsterixComments',
  components: {
    CardForm,
    NewCommentModal: () => import('@/components/organisms/shared/asterixComments/NewCommentModal'),
    AsterixDropdown,
    FeedComment,
    SearchSvg: () => import('@/components/icons/SearchSvg'),
    DeleteModal,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  props: {
    loading: { type: Boolean, default: false },
    commentList: { type: Array, default: () => [] },
    tags: { type: Array, default: COMMENT_TAGS },
  },
  data: () => ({
    keyReset: 0,
    showNewCommentModal: false,
    commentToUpdate: null,
    sortOptions: [
      new SortOption('dateasc', 'Date ascending', 'dateasc', filterDateAscCompare),
      new SortOption('datedesc', 'Date descending', 'datedesc', filterDateDescCompare),
      new SortOption('author', 'Author', 'author', filterAuthorCompare),
      new SortOption('tag', 'Tag', 'tag', filterTagCompare),
    ],
    filters: {
      author: null,
      date: null,
      tag: null,
      sort: null,
    },
    showDeleteModal: false,
  }),
  computed: {
    ...mapGetters({
      usserPermissions: PERMISSIONS,
    }),
    filtersEmpty() {
      return !this.filters.author && !this.filters.date && !this.filters.tag;
    },
    noDataTitle() {
      return this.filtersEmpty
        ? 'A clean, shinny and empty field of comments'
        : `We couldn't find any result for your search`;
    },
    noDataSubtitle() {
      if (this.filtersEmpty && this.canCreate) {
        return `Be the first to publish one. Click on 'New comment' button`;
      }
      return '';
    },
    filteredComments() {
      const comments = this.commentList;
      const appliedFilters = this.getAppliedFilters();
      let filtered = [];

      if (appliedFilters.length > 0) {
        filtered = comments.filter(comment =>
          appliedFilters.map(filter => filter(comment, this.filters)).every(filter => !!filter)
        );
      } else {
        filtered = comments;
      }
      if (this.filters?.sort) {
        filtered = filtered.sort(this.filters.sort.compare);
      }

      return filtered;
    },
    canCreate() {
      return this.usserPermissions.CREATE.find(permission => permission === 'COMMENT');
    },
  },
  beforeMount() {
    this.filters.sort = this.sortOptions[0];
  },
  methods: {
    getAppliedFilters() {
      const applied = [];
      if (this.filters.author) {
        applied.push(this.commentUserFilter);
      }
      if (this.filters.date) {
        applied.push(this.commentDateFilter);
      }
      if (this.filters.tag) {
        applied.push(this.commentTagFilter);
      }
      return applied;
    },
    commentDateFilter(comment, filters) {
      return comment.createdAt >= filters.date.startDate && comment.createdAt <= filters.date.endDate;
    },
    commentUserFilter(comment, filters) {
      const commentLowerCase = comment.author.name.toLowerCase();
      return commentLowerCase.indexOf(filters.author.toLowerCase()) > -1;
    },
    commentTagFilter(comment, filters) {
      return comment.tag === filters.tag;
    },
    onNewComment() {
      this.commentToUpdate = null;
      this.showNewCommentModal = true;
    },
    onEditComment(comment) {
      this.commentToUpdate = comment;
      this.showNewCommentModal = true;
    },
    onSubmitComment(comment) {
      this.$emit('create-or-update', comment);
      this.commentToUpdate = null;
      this.showNewCommentModal = false;
    },
    confirmDeleteModal() {
      this.$emit('delete', this.commentToDelete);
      this.showDeleteModal = false;
    },
    onDeleteComment(item) {
      this.commentToDelete = item;
      this.showDeleteModal = true;
    },
    onUserChange(event) {
      this.filters.author = event.value;
    },
    onDatePickerChange(event) {
      this.filters.date = event.value;
    },
    onSort(event) {
      this.filters.sort = event;
    },
    clearFilters() {
      this.filters = {
        author: null,
        date: null,
        tag: null,
        sort: null,
      };
      this.keyReset++;
    },
  },
};
</script>

<style scoped>
::v-deep #date-picker-multiple > div {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
::v-deep #date-picker-multiple {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

::v-deep .p-1.flex.flex-row.items-start.max-w-full.p-2 {
  display: none;
  padding: 0;
}

.search-svg-absolute {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 8px;
}

.form-button {
  max-width: 75px;
}

.no-data-cnt {
  min-height: 400px;
}
</style>
