var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feed",
    {
      staticClass:
        "p-1 border hover:border-blue-400 rounded-lg last:border-b even:rounded-none",
    },
    [
      _c("template", { slot: "section" }, [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col w-full p-2 overflow-hidden md:w-1/2 lg:w-2/6 xl:w-2/12",
          },
          [
            _c(
              "p",
              {
                staticClass: "font-bold text-left truncate",
                attrs: { title: _vm.comment.author.name },
              },
              [_vm._v(" " + _vm._s(_vm.comment.author.name) + " ")]
            ),
            _c(
              "p",
              {
                staticClass: "text-sm text-left truncate",
                attrs: { title: _vm.createdAt },
              },
              [_vm._v(" " + _vm._s(_vm.createdAt) + " ")]
            ),
            _c(
              "div",
              { staticClass: "flex flex-wrap items-center mt-1" },
              [
                _c(
                  "sun-badge",
                  {
                    staticClass: "text-xs uppercase truncate",
                    attrs: { color: _vm.comment.tag.color },
                  },
                  [_vm._v(" " + _vm._s(_vm.comment.tag.name) + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("template", { slot: "main" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full p-2 overflow-hidden md:w-1/2 lg:w-4/6 xl:w-10/12",
          },
          [
            _c("p", { staticClass: "w-full text-left" }, [
              _vm._v(_vm._s(_vm.comment.content)),
            ]),
          ]
        ),
      ]),
      _vm.canUpdate
        ? _c(
            "template",
            { slot: "actions" },
            [
              _c("asterix-dropdown", {
                attrs: { options: _vm.actions, "option-list-position": "left" },
                on: {
                  change: function ($event) {
                    return _vm.actionClick($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-end h-full p-1",
                            },
                            [
                              _c("menu-dots-svg", {
                                staticClass: "h-5 text-gray-500",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  480281275
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }