import { stringToDate } from '@/filters/dateFilters';

export const filterDateAscCompare = (a, b) => {
  return stringToDate(a.createdAt).getTime() - stringToDate(b.createdAt).getTime();
};
export const filterDateDescCompare = (a, b) => {
  return stringToDate(b.createdAt).getTime() - stringToDate(a.createdAt).getTime();
};
export const filterUserCompare = (a, b) => {
  const x = a.user.name.toLowerCase();
  const y = b.user.name.toLowerCase();

  if (x > y) {
    return 1;
  }
  if (x < y) {
    return -1;
  }
  return 0;
};
export const filterAuthorCompare = (a, b) => {
  const x = a.author.name.toLowerCase();
  const y = b.author.name.toLowerCase();
  if (x > y) return 1;
  if (x < y) return -1;
  return 0;
};
export const filterTagCompare = (a, b) => {
  const x = a.tag.name.toLowerCase();
  const y = b.tag.name.toLowerCase();

  if (x > y) {
    return 1;
  }
  if (x < y) {
    return -1;
  }
  return 0;
};
