<template>
  <feed class="p-1 border hover:border-blue-400 rounded-lg last:border-b even:rounded-none">
    <template slot="section">
      <div class="flex flex-col w-full p-2 overflow-hidden md:w-1/2 lg:w-2/6 xl:w-2/12">
        <p class="font-bold text-left truncate" :title="comment.author.name">
          {{ comment.author.name }}
        </p>
        <p class="text-sm text-left truncate" :title="createdAt">
          {{ createdAt }}
        </p>
        <div class="flex flex-wrap items-center mt-1">
          <sun-badge :color="comment.tag.color" class="text-xs uppercase truncate">
            {{ comment.tag.name }}
          </sun-badge>
        </div>
      </div>
    </template>
    <template slot="main">
      <div class="w-full p-2 overflow-hidden md:w-1/2 lg:w-4/6 xl:w-10/12">
        <p class="w-full text-left">{{ comment.content }}</p>
      </div>
    </template>
    <template v-if="canUpdate" slot="actions">
      <asterix-dropdown :options="actions" option-list-position="left" @change="actionClick($event)">
        <template #icon>
          <div class="flex items-center justify-end h-full p-1">
            <menu-dots-svg class="h-5 text-gray-500" />
          </div>
        </template>
      </asterix-dropdown>
    </template>
  </feed>
</template>

<script>
import { mapState } from 'vuex';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { DateFormatter } from '@/model/shared/DateFormatter';
import { applyDateFormatters } from '@/filters/dateFilters';
import Feed from '@/components/atoms/Feed';
import { AUTH_NS, USER_KEY } from '@/store/modules/auth/keys';

export default {
  name: 'FeedComment',
  components: {
    Feed,
    AsterixDropdown,
    MenuDotsSvg: () => import('@/components/icons/MenuDotsSvg'),
  },
  props: {
    comment: { type: Object, required: true },
  },
  data: () => ({
    actions: [
      { id: 'Edit', name: 'Edit', type: 'edit' },
      { id: 'Delete', name: 'Delete', type: 'delete' },
    ],
  }),
  computed: {
    ...mapState(AUTH_NS, {
      user: USER_KEY,
    }),
    canUpdate() {
      // This property 'canUpdate' is set by back. same time back dont set this property,
      // and then fornt has a new task to fixing this.
      return this.comment.canUpdate || this.user.id === this.comment.author.id;
    },
    createdAt() {
      return applyDateFormatters(
        this.comment.createdAt,
        new DateFormatter({ day: '2-digit', month: '2-digit', year: 'numeric' }, 'es-ES')
      );
    },
  },
  methods: {
    actionClick({ type }) {
      this.$emit(type, this.comment);
    },
  },
};
</script>

<style scoped>
.no-data-cnt {
  min-height: 400px;
}
</style>
