<template functional>
  <div class="flex w-full p-2" :class="[data.class, data.staticClass]">
    <slot name="wrapper">
      <div class="flex flex-grow flex-wrap w-56">
        <slot name="aside"></slot>
        <slot name="section"></slot>
        <slot name="main"></slot>
      </div>
      <div class="flex-no-grow">
        <slot name="actions"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Feed',
};
</script>
